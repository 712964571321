import PublicLayout from "@/layouts/AuthorizedLayout.vue";

export default [
    {
        meta: PublicLayout,
        name: 'auth-login',
        path: '/',
        component: () => import('/src/pages/auth/TheLogin/TheLogin.vue'),
    },
    {
        meta: PublicLayout,
        name: 'auth-logout',
        path: '/logout',
        component: () => import('/src/pages/auth/TheLogout/TheLogout.vue'),
    },

];
