<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="117"
    height="17"
    viewBox="0 0 117 17"
    fill="none"
  >
    <path
      d="M13.9809 0.793771L12.6281 8.41631C12.4349 9.51138 12.1128 11.4224 11.984 12.5175H11.8981C11.7478 11.4224 11.4257 9.55433 11.2325 8.37337L9.90119 1.00849H7.13131L5.80005 8.37337C5.60681 9.55433 5.28473 11.4224 5.13442 12.5175H5.04853C4.9197 11.4224 4.59762 9.51138 4.40438 8.41631L3.05164 0.793771H0.324707L3.6958 15.8241H6.50863L7.754 10.0482C8.09755 8.52367 8.37669 6.39795 8.48405 5.17405H8.56993C8.65582 6.39795 8.93496 8.52367 9.27851 10.0482L10.5239 15.8241H13.3367L16.7078 0.793771H13.9809Z"
      fill="#1A295A"
    />
    <path
      d="M24.5974 16.0818C27.5605 16.0818 29.3212 14.1708 29.3212 10.9929V5.62496C29.3212 2.44711 27.5605 0.536109 24.5974 0.536109C21.6128 0.536109 19.8521 2.44711 19.8521 5.62496V10.9929C19.8521 14.1708 21.6128 16.0818 24.5974 16.0818ZM24.5974 13.5266C23.2661 13.5266 22.579 12.6248 22.579 10.9929V5.62496C22.579 3.99309 23.2661 3.09127 24.5974 3.09127C25.9287 3.09127 26.5943 3.99309 26.5943 5.62496V10.9929C26.5943 12.6248 25.9287 13.5266 24.5974 13.5266Z"
      fill="#1A295A"
    />
    <path
      d="M40.2977 15.8241H43.1965L40.1689 10.1126C41.6934 9.38255 42.5308 7.83657 42.5308 5.64643C42.5308 2.57594 40.8345 0.793771 37.8929 0.793771H33.7703V15.8241H36.4113V10.585H37.6138L40.2977 15.8241ZM36.4113 3.30599H37.8714C39.1383 3.30599 39.8254 4.12192 39.8254 5.6679C39.8254 7.23536 39.1383 8.07276 37.8714 8.07276H36.4113V3.30599Z"
      fill="#1A295A"
    />
    <path
      d="M53.5594 15.8241H56.5654L51.8846 7.34272L55.9213 0.793771H52.8723L50.36 5.19552L49.4797 6.95622V0.793771H46.8816V15.8241H49.4797V11.1433L50.403 9.70463L53.5594 15.8241Z"
      fill="#1A295A"
    />
    <path
      d="M63.5163 16.1247C66.415 16.1247 68.0684 14.2567 68.0684 11.8948C68.0684 7.17094 61.9488 7.08505 61.9488 4.46547C61.9488 3.6066 62.5286 3.04833 63.5163 3.04833C64.5255 3.04833 65.1267 3.62807 65.1482 4.8305H67.8536C67.8322 2.1465 66.2003 0.493164 63.5163 0.493164C60.8538 0.493164 59.2649 2.16798 59.2649 4.40106C59.2649 8.93164 65.3199 9.14636 65.3199 11.8733C65.3199 12.8395 64.7402 13.5481 63.5378 13.5481C62.3998 13.5481 61.6912 12.9254 61.6697 11.5727H58.9642C58.9857 14.4714 60.7464 16.1247 63.5163 16.1247Z"
      fill="#1A295A"
    />
    <path
      d="M76.0326 0.793771H71.867V15.8241H74.5081V10.7782H76.0326C79.0601 10.7782 80.8423 8.95311 80.8423 5.79674C80.8423 2.64036 79.0601 0.793771 76.0326 0.793771ZM76.0111 8.28748H74.5081V3.30599H76.0111C77.4283 3.30599 78.1369 4.1434 78.1369 5.79674C78.1369 7.45008 77.4283 8.28748 76.0111 8.28748Z"
      fill="#1A295A"
    />
    <path
      d="M90.1133 15.8241H92.8617L89.1686 0.793771H86.3343L82.6411 15.8241H85.3895L85.9907 13.0972H89.5121L90.1133 15.8241ZM86.5275 10.585L87.1287 7.79363C87.3435 6.76297 87.5796 5.10963 87.7085 4.10045H87.7729C87.9232 5.10963 88.1594 6.76297 88.3741 7.79363L88.9753 10.585H86.5275Z"
      fill="#1A295A"
    />
    <path
      d="M100.219 16.0818C103.118 16.0818 104.814 14.407 104.922 11.358H102.281C102.173 12.861 101.529 13.5266 100.219 13.5266C99.1027 13.5266 98.4371 12.8181 98.4371 11.315V5.30288C98.4371 3.7569 99.1027 3.04833 100.198 3.04833C101.486 3.04833 102.13 3.71396 102.216 5.25994H104.857C104.728 2.18945 103.096 0.493164 100.219 0.493164C97.4064 0.493164 95.7101 2.18945 95.7101 5.30288V11.315C95.7101 14.407 97.4279 16.0818 100.219 16.0818Z"
      fill="#1A295A"
    />
    <path
      d="M111.514 13.3334V9.40402H116.024V6.99916H111.514V3.28452H116.432V0.793771H108.873V15.8241H116.646V13.3334H111.514Z"
      fill="#1A295A"
    />
  </svg>
</template>

<script setup></script>
