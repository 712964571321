import AuthorizedLayout from "@/layouts/AuthorizedLayout.vue";

export default [
    {
        meta: {layout: AuthorizedLayout},
        name: 'tickets-list',
        path: '/tickets',
        component: () => import('/src/pages/ticket/TicketList/TicketList.vue'),
    },
    {
        meta: {
            layout: AuthorizedLayout,
        },
        name: 'tickets-item',
        path: '/tickets/:id',
        component: () => import('/src/pages/ticket/TicketItem/TicketItem.vue')
    }
];
