import AuthorizedLayout from "@/layouts/AuthorizedLayout.vue";

export default [
    {
        meta: {layout: AuthorizedLayout},
        name: 'categories-list',
        path: '/categories',
        component: () => import('/src/pages/category/CategoryList/CategoryList.vue'),
    }
];
