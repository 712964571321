<template>
  <el-config-provider>
    <el-container>
      <keep-alive>
        <TheSidebar/>
      </keep-alive>
      <el-container id="main_container">
        <TheHeader/>
        <main class="main">
          <div class="wrapper">
            <slot/>
          </div>
        </main>
      </el-container>
    </el-container>
  </el-config-provider>
</template>
<script setup>

import TheSidebar from "@/components/TheSidebar.vue";
import TheHeader from '@/components/MainHeader.vue';
import {computed, watch} from "vue";
import {useRouter} from "vue-router";

const router = useRouter();
const token = computed(() => localStorage.getItem('token'));
watch(() => token.value, newVal => {
  if (!newVal) {
    router.push('/login');
  }
})
</script>