import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import ElementPlus from 'element-plus'
import './assets/styles/index.scss';
import { createI18n } from "vue-i18n";
import ru from "./assets/translations/ru.json";


const i18n = createI18n({
    locale: "ru",
    legacy:false,
    fallbackLocale: "ru",
    messages: { ru },
});

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.use(i18n);
app.mount('#app')