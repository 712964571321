import AuthorizedLayout from "@/layouts/AuthorizedLayout.vue";

export default [
    {
        meta: {layout: AuthorizedLayout},
        name: 'dashboard-list',
        path: '/dashboard',
        component: () => import('/src/pages/dashboard/DashboardList/DashboardList.vue'),
    }
];
