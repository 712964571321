<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="50"
      viewBox="0 0 218 50"
      fill="none"
  >
    <path
        d="M28.5157 28.4409V11.2064H10.7V28.4409H14.2603V18.1031L24.9603 28.4409H28.5205H28.5157ZM24.9555 49.1408L14.2555 38.7933H0V0.858887H39.2157V38.7933H24.9603V49.1408H24.9555Z"
        :fill="props.color"
    />
    <path
        d="M73.2758 9.08642L71.3689 19.9109C71.0965 21.4659 70.6424 24.1796 70.4608 25.7347H70.3397C70.1279 24.1796 69.6738 21.5269 69.4014 19.8499L67.5248 9.39134H63.6202L61.7435 19.8499C61.4711 21.5269 61.0171 24.1796 60.8052 25.7347H60.6841C60.5025 24.1796 60.0485 21.4659 59.7761 19.9109L57.8692 9.08642H54.0251L58.7772 30.4304H62.7424L64.498 22.2282C64.9822 20.0633 65.3757 17.0447 65.5271 15.3067H65.6482C65.7692 17.0447 66.1627 20.0633 66.647 22.2282L68.4026 30.4304H72.3677L77.1198 9.08642H73.2758Z"
        :fill="props.color"
    />
    <path
        d="M88.2416 30.7963C92.4186 30.7963 94.9006 28.0825 94.9006 23.5698V15.947C94.9006 11.4343 92.4186 8.72053 88.2416 8.72053C84.0343 8.72053 81.5523 11.4343 81.5523 15.947V23.5698C81.5523 28.0825 84.0343 30.7963 88.2416 30.7963ZM88.2416 27.1678C86.3649 27.1678 85.3963 25.8872 85.3963 23.5698V15.947C85.3963 13.6296 86.3649 12.349 88.2416 12.349C90.1182 12.349 91.0565 13.6296 91.0565 15.947V23.5698C91.0565 25.8872 90.1182 27.1678 88.2416 27.1678Z"
        :fill="props.color"
    />
    <path
        d="M110.374 30.4304H114.46L110.192 22.3197C112.341 21.283 113.522 19.0876 113.522 15.9775C113.522 11.6172 111.131 9.08642 106.984 9.08642H101.172V30.4304H104.895V22.9905H106.59L110.374 30.4304ZM104.895 12.6539H106.954C108.739 12.6539 109.708 13.8126 109.708 16.008C109.708 18.2338 108.739 19.423 106.954 19.423H104.895V12.6539Z"
        :fill="props.color"
    />
    <path
        d="M129.068 30.4304H133.306L126.707 18.3863L132.398 9.08642H128.1L124.558 15.3372L123.317 17.8374V9.08642H119.655V30.4304H123.317V23.7833L124.619 21.7403L129.068 30.4304Z"
        :fill="props.color"
    />
    <path
        d="M143.104 30.8572C147.191 30.8572 149.521 28.2045 149.521 24.8505C149.521 18.1424 140.895 18.0204 140.895 14.3004C140.895 13.0808 141.712 12.288 143.104 12.288C144.527 12.288 145.374 13.1113 145.405 14.8188H149.219C149.188 11.0074 146.888 8.65955 143.104 8.65955C139.351 8.65955 137.111 11.0379 137.111 14.209C137.111 20.6426 145.647 20.9476 145.647 24.82C145.647 26.1921 144.83 27.1983 143.135 27.1983C141.53 27.1983 140.532 26.314 140.501 24.3931H136.687C136.718 28.5094 139.2 30.8572 143.104 30.8572Z"
        :fill="props.color"
    />
    <path
        d="M160.748 9.08642H154.876V30.4304H158.599V23.2649H160.748C165.016 23.2649 167.528 20.6731 167.528 16.1909C167.528 11.7087 165.016 9.08642 160.748 9.08642ZM160.718 19.7279H158.599V12.6539H160.718C162.716 12.6539 163.714 13.8431 163.714 16.1909C163.714 18.5387 162.716 19.7279 160.718 19.7279Z"
        :fill="props.color"
    />
    <path
        d="M180.597 30.4304H184.472L179.266 9.08642H175.27L170.064 30.4304H173.938L174.786 26.558H179.75L180.597 30.4304ZM175.543 22.9905L176.39 19.0266C176.693 17.563 177.026 15.2152 177.207 13.7821H177.298C177.51 15.2152 177.843 17.563 178.146 19.0266L178.993 22.9905H175.543Z"
        :fill="props.color"
    />
    <path
        d="M194.843 30.7963C198.93 30.7963 201.321 28.4179 201.472 24.0882H197.749C197.598 26.2226 196.69 27.1678 194.843 27.1678C193.269 27.1678 192.331 26.1616 192.331 24.0272V15.4896C192.331 13.2942 193.269 12.288 194.813 12.288C196.629 12.288 197.537 13.2332 197.658 15.4286H201.381C201.2 11.0684 198.899 8.65955 194.843 8.65955C190.878 8.65955 188.487 11.0684 188.487 15.4896V24.0272C188.487 28.4179 190.908 30.7963 194.843 30.7963Z"
        :fill="props.color"
    />
    <path
        d="M210.766 26.8934V21.3135H217.122V17.8984H210.766V12.6234H217.697V9.08642H207.043V30.4304H218V26.8934H210.766Z"
        :fill="props.color"
    />
  </svg>
</template>

<script setup>
import {defineProps} from 'vue'
const props = defineProps({
  color: {
    default: '#1A295A'
  }
})
</script>
