import {createRouter, createWebHistory} from 'vue-router';
import auth from './apps/auth';
import tickets from "./apps/tickets";
import organizations from "@/router/apps/organizations";
import categories from './apps/categories';
import dashboard from './apps/dashboard'

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        ...auth,
        ...tickets,
        ...organizations,
        ...categories,
        ...dashboard
    ],
});

export default router;
