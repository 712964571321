<template>
  <el-menu :style="{ width: `${sidebarWidth}px` }"
           class="sidebar"
           @mouseenter="(e) => (sidebarWidth = 280)"
           @mouseleave="(e) => (sidebarWidth = 70)">
    <router-link
        class="pl-05 flex items-center logo-container"
        to="/tickets"
    >
      <IconLogoShort/>
      <IconWorkspace/>
    </router-link>
    <el-row class="mb-0 mt--05">
      <el-divider/>
    </el-row>
    <template v-for="(menuItem,index) in menuItems" :key="menuItem.route">
      <el-menu-item :index="index">
        <template #title>
          <router-link :to="menuItem.route" class="sidebar-link">
            <el-icon v-if="menuItem.icon">
              <component :is="menuItem.icon"/>
            </el-icon>
            <span v-if="showText">{{ menuItem.text }}</span>
          </router-link>
        </template>
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script setup>
import IconLogoShort from '@/components/Icons/IconLogoShort.vue';
import IconWorkspace from '@/components/Icons/IconWorkspace.vue';
import {computed, ref} from "vue";
import {OfficeBuilding, Tickets, Collection, Calendar} from "@element-plus/icons-vue"
import Logout from "@/assets/icons/IconLogout.vue";

const menuItems = [
  {
    icon:Calendar,
    route:"/dashboard",
    text:('Статистика')
  },
  {
    route: "/organizations",
    icon: OfficeBuilding,
    text: 'Организации'
  },
  {
    icon:Collection,
    route:"/categories",
    text:"Категории"
  },
  {
    route: "/tickets",
    icon: Tickets,
    text: 'Заявки'
  },
  {
    icon: Logout,
    route: "/logout",
    text: 'Выйти'
  }
]
const sidebarWidth = ref(70);
const showText = computed(() => sidebarWidth.value > 70)
</script>