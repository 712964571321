import AuthorizedLayout from "@/layouts/AuthorizedLayout.vue";

export default [
    {
        meta: {
            layout: AuthorizedLayout,
        },
        name: 'organizations-list',
        path: '/organizations',
        component: () => import('/src/pages/organization/OrganizationList.vue'),
    },
];
