<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="34"
    viewBox="0 0 28 34"
    fill="none"
  >
    <path
      d="M20.2287 19.4232V7.28669H7.59044V19.4232H10.116V12.1433L17.7065 19.4232H20.2321H20.2287ZM17.7031 34L10.1126 26.7133H0V0H27.8191V26.7133H17.7065V34H17.7031Z"
      fill="#F5F5F5"
    />
  </svg>
</template>

<script setup></script>
